import { Typography, Box, Alert, AlertTitle, AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { EventLog, getEventLog, LogEntry } from "./Api/Log";
import moment from "moment";
import { DoorControl } from "./DoorControl";
import useWebSocket, { ReadyState } from "react-use-websocket";

const wsProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";
const eventUrl = `${wsProtocol}//${window.location.host}/api/door/events`;

export const App = () => {
  const [events, setEvents] = useState([] as EventLog);

  const { lastMessage, readyState } = useWebSocket(
    eventUrl,
    {
      shouldReconnect: (closeEvent) => true,
    });

  useEffect(() => {
    loadEvents();
  }, [lastMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: { severity: "info" as AlertColor, message: 'Connecting' },
    [ReadyState.OPEN]: { severity: "success" as AlertColor, message: 'Connected' },
    [ReadyState.CLOSING]: { severity: "info" as AlertColor, message: 'Closing' },
    [ReadyState.CLOSED]: { severity: "error" as AlertColor, message: 'Not connected.' },
    [ReadyState.UNINSTANTIATED]: { severity: "error" as AlertColor, message: 'Uninstantiated' },
  }[readyState];

  const loadEvents = async () => {
    const eventLog = await getEventLog();
    eventLog.sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime());
    setEvents(eventLog);
  };

  return (
    <Box sx={{ m: 3 }} maxWidth="sm">
      <Typography variant="h5" sx={{ textAlign: "center" }}>Sesame<sup style={{ fontSize: "10px" }}>TM</sup> Door Control</Typography>
      <Typography variant="h4" sx={{ textAlign: "center" }}>Cobra & Vise</Typography>
      <Alert severity={connectionStatus.severity}>{connectionStatus.message}</Alert>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }} >
        <DoorControl title="Door 1" doorId="0" />
        <DoorControl title="Door 2" doorId="1" />
        <DoorControl title="Door 3" doorId="2" />
      </Box>
      <Box sx={{ marginTop: 2 }}>
        {events.map((evt) =>
        (<Alert key={evt.timeStamp} severity={getLogEventSeverity(evt)}>
          <AlertTitle>{moment(evt.timeStamp).format("llll")} ({moment(evt.timeStamp).fromNow()})</AlertTitle>
          {getLogEventMessage(evt)}
        </Alert>)
        )}
      </Box>
    </Box>
  );
};

const getLogEventSeverity = (entry: LogEntry) => {
  switch (entry.event) {
    case "up": return "success";
    case "stop": return "error";
    case "down": return "info";
    case "startup": return "success";
  }
};

const getLogEventMessage = (entry: LogEntry) => {
  switch (entry.event) {
    case "up": return `Requested door ${entry.doorId} up`;
    case "stop": return `Requested door ${entry.doorId} stop`;
    case "down": return `Requested door ${entry.doorId} down`;
    case "startup": return "Program startup";
  }
};