
export type Response = {
    status: number;
    message: string;
};

export const cmdDoorUp = async (doorId: string): Promise<Response> => {
    const resp = await fetch(`/api/door/${doorId}/up`, {
        method: "POST",
    });
    return (await resp.json()) as Response;
};

export const cmdDoorStop = async (doorId: string): Promise<Response> => {
    const resp = await fetch(`/api/door/${doorId}/stop`, {
        method: "POST",
    });
    return (await resp.json()) as Response;
};

export const cmdDoorDown = async (doorId: string): Promise<Response> => {
    const resp = await fetch(`/api/door/${doorId}/down`, {
        method: "POST",
    });
    return (await resp.json()) as Response;
};