
export type LogEntry = {
    timeStamp: string,
    event: string,
    doorId: number,
};

export type EventLog = LogEntry[];

export const getEventLog = async (): Promise<EventLog> => {
    const resp = await fetch(`/api/door/logs`, {
        method: "GET",
    });
    return (await resp.json()) as EventLog;
};
