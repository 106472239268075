import { faUpToDottedLine, faCircleStop, faDownToLine } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardHeader, CardContent, Stack, Button } from "@mui/material";
import { cmdDoorDown, cmdDoorStop, cmdDoorUp } from "./Api/Door";

export const DoorControl = (props: { title: string, doorId: string }) => {
    const { title, doorId } = props;

    const onDoorUp = () => cmdDoorUp(doorId);
    const onDoorStop = () => cmdDoorStop(doorId);
    const onDoorDown = () => cmdDoorDown(doorId);


    return (<Card variant="outlined" sx={{ width: 200 }}>
        <CardHeader title={title} />
        <CardContent>
            <Stack spacing={2}>
                <Button variant="outlined" color="success"
                    startIcon={<FontAwesomeIcon icon={faUpToDottedLine} />}
                    onClick={onDoorUp}>
                    Up
                </Button>
                <Button variant="outlined" color="error"
                    startIcon={<FontAwesomeIcon
                        icon={faCircleStop} />}
                    onClick={onDoorStop}>
                    Stop
                </Button>
                <Button variant="outlined" color="primary"
                    startIcon={<FontAwesomeIcon
                        icon={faDownToLine} />}
                    onClick={onDoorDown}>
                    Down
                </Button>
            </Stack>
        </CardContent>
    </Card>);
};